import React from 'react';
import {
  ChakraProvider,
  Box,
  Button,
  Flex,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  Wrap,
  WrapItem,
  theme,
  useBreakpointValue
} from '@chakra-ui/react';
import FeatureStack from './FeatureStack';
import HeroImage from './HeroImage';

export default function ContentPageWithHeader(props) {
  return (
    <Flex
      bg='#fcfcfa'
      direction="column">
      <HeroImage
        title={props.title}
        baseHeight={props.baseHeight ? props.baseHeight : '100px'}
        mdHeight={props.baseHeight ? props.baseHeight : '200px'}
      />
      <Flex
        bg={'#3B3E4B'}
        minH={'10px'}
        w={'100%'}>
      </Flex>
      {props.children}
      <Flex
        bg={'#3B3E4B'}
        minH={'10px'}
        w={'100%'}>
      </Flex>
      <HeroImage
        title={null}
        baseHeight={'20px'}
        mdHeight={'20px'}
      />
    </Flex>
  )
}

import React from 'react';
import {
  ChakraProvider,
  Box,
  Button,
  Flex,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  Wrap,
  WrapItem,
  theme,
  useBreakpointValue
} from '@chakra-ui/react';
import FeatureStack from './FeatureStack';
import HeroImage from './HeroImage';
import ContentPageWithHeader from './ContentPageWithHeader'

export default function Shop() {
  return (
    <ContentPageWithHeader title='Shop Digital Planners'>
      <Flex
        justify={'center'}>
        <Flex
          maxW={'1600'}
          flexWrap={'wrap'}>
          <VStack
            spacing={'6'}
            alignItems={'flex-start'}
            p={20}>
            <Text
              fontSize={'3xl'}
              fontFamily={'DM-Sans Bold'}
              color={'3B3E4B'}>
              Browse PandoNote on Etsy
            </Text>
            <Text
              fontSize={'l'}
              fontFamily={'DM-Sans'}
              color={'3B3E4B'}>
              Our planners & stickers are currently available in the PandoNote Etsy shop.
            </Text>
            <Link
              href='https://www.etsy.com/shop/pandonote'
              _hover={{
                textDecoration: 'none'
              }}>
              <Button
                color='white'
                borderColor='#89717E'
                bg='#89717E'
                _hover={{
                  bg: '#97AE90',
                }}
                variant='solid'>
                Shop Planners on Etsy
              </Button>
            </Link>
          </VStack>
        </Flex>
      </Flex>
    </ContentPageWithHeader>
  )
}

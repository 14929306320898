import React from 'react';
import {
  ChakraProvider,
  Box,
  Button,
  Flex,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  Wrap,
  WrapItem,
  theme,
  useBreakpointValue
} from '@chakra-ui/react';
import Header from '../layout/Header';

export default function FeatureStack(props) {
  return (
    <VStack
      spacing={'6'}
      maxW={useBreakpointValue({ base: '100%', md: '50%' })}
      alignItems={'flex-start'}
      p={20}>
      <Text
        fontSize={'3xl'}
        fontFamily={'DM-Sans Bold'}
        color={'3B3E4B'}>
        {props.title}
      </Text>
      <Text
        fontSize={'l'}
        fontFamily={'DM-Sans'}
        color={'3B3E4B'}>
        {props.body}
      </Text>
      <Link
        href={props.link ? props.link : '#'}
        _hover={{
          textDecoration: 'none'
        }}>
        <Button
          color='white'
          borderColor='#89717E'
          bg='#89717E'
          variant='solid'
          _hover={{
            bg: '#97AE90'
          }}
          _focus={{
            bg: '#97AE90'
          }}>
          {props.buttonText}
          </Button>
        </Link>
    </VStack>
  )
}

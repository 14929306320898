import {
  Stack,
  Flex,
  Button,
  Text,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';

export default function HeroImage(props) {
  return (
    <Flex
      w={'full'}
      h={useBreakpointValue({ base: props.baseHeight, md: props.mdHeight })}
      backgroundImage={
        'url(/header3.png)'
      }
      backgroundSize={'auto'}
      backgroundPosition={'center center'}>
      <VStack
        w={'full'}
        justify={'center'}
        px={useBreakpointValue({ base: 4, md: 8 })}
        bgGradient={'linear(to-r, blackAlpha.300, transparent)'}
        >
        <Text
          color={'white'}
          lineHeight={1.2}
          fontFamily={'ClashDisplay-Bold'}
          textAlign={'center'}
          fontSize={useBreakpointValue({ base: '4xl', md: '6xl' })}>
          {props.title}
        </Text>
      </VStack>
    </Flex>
  );
}

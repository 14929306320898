import React from 'react';
import {
  ChakraProvider,
  Box,
  Button,
  Flex,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  Wrap,
  WrapItem,
  Image,
  Stack,
  Heading,
  Avatar,
  theme,
  useBreakpointValue
} from '@chakra-ui/react';
import FeatureStack from '../FeatureStack';
import HeroImage from '../HeroImage';
import ContentPageWithHeader from '../ContentPageWithHeader'

function TutorialCard(props) {
  return (
    <Link
      href={props.link}
      _hover={{
        textDecoration: 'none',
        backgroundColor: '#5f4f58'
      }}
      maxW={'445px'}
      w={'full'}
      bg={'#363e4b'}
      boxShadow={'xl'}
      rounded={'md'}
      p={6}
      m={6}
      overflow={'hidden'}>
      <Stack>
        <Text
          color={'#b88a54'}
          textTransform={'uppercase'}
          fontWeight={800}
          fontFamily={'DMSans-Bold'}
          fontSize={'sm'}
          letterSpacing={1.1}>
          {props.category}
        </Text>
        <Heading
          color={'#97ae90'}
          fontSize={'2xl'}
          fontFamily={'ClashDisplay-Semibold'}>
          {props.title}
        </Heading>
        <Text color={'#F5F6F0'}
        fontFamily={'DMSans-Regular'}>
          {props.body}
        </Text>
      </Stack>
    </Link>
  )
}

export default function Tutorials() {
  return (
    <ContentPageWithHeader title='Tutorials'>
      <Flex
        justify={'center'}>
        <Flex
          maxW={'1600'}
          flexWrap={'wrap'}
          justify={'center'}
          p={'20px'}>
          <TutorialCard
            category='Digital Planners'
            title='Download and import your GoodNotes planner'
            body='Just getting started? This article will help you learn how to download your new planner from Etsy and import it into your digital planner app.'
            link='/tutorials/download-planner'
          />
          <TutorialCard
            category='Stickers'
            title='Download and use your planner stickers'
            body='Looking to add some stickers to your planner? Check out this article to learn methods for importing and using stickers'
            link='/tutorials/download-stickers'
          />
        </Flex>
      </Flex>
    </ContentPageWithHeader>
  )
}

import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import {
  ChakraProvider,
  Flex,
  theme,
} from '@chakra-ui/react';
import Header from './layout/Header'
import Footer from './layout/Footer';
import Home from './content/Home';
import Shop from './content/Shop';
import Freebies from './content/Freebies';
import About from './content/About';
import Faq from './content/Faq';
import Tutorials from './content/tutorials/Tutorials';
import DownloadPlanner from './content/tutorials/DownloadPlanner';
import DownloadStickers from './content/tutorials/DownloadStickers';

function App() {
  return (
    <ChakraProvider theme={theme}>
    <Flex
      bg='#fcfcfa'
      direction="column">
      <Header/>
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/shop' element={<Shop />}/>
        <Route path='/freebies' element={<Freebies />}/>
        <Route path='/about' element={<About />}/>
        <Route path='/faq' element={<Faq />}/>
        <Route path='/tutorials' element={<Tutorials />}/>
        <Route path='/tutorials/download-planner' element={<DownloadPlanner />}/>
        <Route path='/tutorials/download-stickers' element={<DownloadStickers />}/>

      </Routes>
      <Footer/>
    </Flex>
    </ChakraProvider>
  );
}

export default App;

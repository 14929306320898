import { ButtonGroup, Container, IconButton, Stack, Text } from '@chakra-ui/react'
import * as React from 'react'
import { FaInstagram, FaTiktok } from 'react-icons/fa'

export default function Footer() {
  return (
    <Container as="footer" role="contentinfo" py={{ base: '12', md: '16' }}>
      <Stack spacing={{ base: '4', md: '5' }}>
        <Stack justify="space-between" direction="row" align="center">
          <Text
            color='#97AE90'
            fontSize='3xl'
            fontFamily='ClashDisplay-Semibold'>
            pandonote
          </Text>
          <ButtonGroup variant="ghost">
            <IconButton
              as="a"
              href="https://www.instagram.com/pandonote/"
              aria-label="Instagram"
              icon={<FaInstagram fontSize="1.25rem" />}
            />
            <IconButton as="a" href="https://www.tiktok.com/@pandonote" aria-label="Tiktok" icon={<FaTiktok fontSize="1.25rem" />} />
          </ButtonGroup>
        </Stack>
        <Text fontSize="sm" color="subtle">
          &copy; {new Date().getFullYear()} PandoNote. All rights reserved.
        </Text>
      </Stack>
    </Container>
  )
}

import React from 'react';
import {
  ChakraProvider,
  Box,
  Button,
  Flex,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  Wrap,
  WrapItem,
  theme,
  useBreakpointValue
} from '@chakra-ui/react';
import FeatureStack from './FeatureStack';
import HeroImage from './HeroImage';
import ContentPageWithHeader from './ContentPageWithHeader'


export default function Freebies() {
  return (
    <ContentPageWithHeader title='Freebies'>
      <Flex
        justify={'center'}>
        <Flex
          maxW={'1600'}
          flexWrap={'wrap'}>
          <VStack
            spacing={'6'}
            alignItems={'flex-start'}
            p={20}>
            <Text
              fontSize={'3xl'}
              fontFamily={'DM-Sans Bold'}
              color={'3B3E4B'}>
              Coming Soon! Stay tuned for free stickers and planner previews!
            </Text>
          </VStack>
        </Flex>
      </Flex>
    </ContentPageWithHeader>
  )
}

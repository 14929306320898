import React from 'react';
import {
  ChakraProvider,
  Box,
  Button,
  Flex,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  Wrap,
  WrapItem,
  OrderedList,
  ListItem,
  theme,
  useBreakpointValue
} from '@chakra-ui/react';
import FeatureStack from '../FeatureStack';
import HeroImage from '../HeroImage';
import ContentPageWithHeader from '../ContentPageWithHeader'
import TutorialSection from './TutorialSection'

const findYourFilesParagraphs = [
  <Text>Please note that files can only be downloaded from the <Link color='#89717E' href='https://www.etsy.com/'>Etsy website</Link> — the app does not currently support downloading digital files. Please use a browser like Chrome or Safari.</Text>,
  <Text fontWeight='bold'>If you purchased while logged in to your Etsy account:</Text>,
  <OrderedList pl={4}>
    <ListItem key='1'>Log in to your Etsy account.</ListItem>
    <ListItem key='2'>Click on your profile photo and navigate to <Link color='#89717E' fontWeight='bold' href='https://www.etsy.com/your/purchases'>Your Purchases & Reviews</Link>.</ListItem>
    <ListItem key='3'>Next to your order, click 'Download Files'. This will show you the files available for this order.</ListItem>
  </OrderedList>,
  <Text>There is not limit to how many times you can download a file, so if you lose or can't find your file, feel free to download it again using this method.</Text>,
  <Text fontWeight='bold'>If you purchased while logged out:</Text>,
  <Text>When you purchase as a guest, a link to download is sent to you in the receipt email. Check your inbox or spam folder to find the email sent to you after purcahse.</Text>
]

const downloadFilesParagraphs = [
  <Text fontWeight='bold'>From an iPad/tablet:</Text>,
  <OrderedList pl={4}>
    <ListItem key='1a'>Click the download files button to the right of your order.</ListItem>
    <ListItem key='2a'>Choose the file you would like to download and tap on it.</ListItem>
    <ListItem key='3a'>In the download popup, tap the 'Download' button.</ListItem>
    <ListItem key='4a'>(On an iPad) Open the 'Files' app and navigate to the Downloads folder (either within iCloud or On This iPad). For non-iPad devices, find the downloads folder for your tablet.</ListItem>
  </OrderedList>,
  <Text>You can either leave this file where it is in the Downloads folder, or relocate it to a more permanent folder of your choosing.</Text>,
  <Text fontWeight='bold'>From a smart phone:</Text>,
  <OrderedList pl={4}>
    <ListItem key='1b'>Click on the photo of the order you would like to download.</ListItem>
    <ListItem key='2b'>Scroll down and find the button labeled 'Download Files'</ListItem>
    <ListItem key='3b'>Choose the file you would like to download and tap on it.</ListItem>
    <ListItem key='4b'>In the download popup, tap the 'Download' button.</ListItem>
    <ListItem key='5b'>(On an iPhone) Open the 'Files' app and navigate to the Downloads folder (either within iCloud or On This iPhone). For non-iPhone phones, you may need to look up how to find downloads on your particular phone.</ListItem>
  </OrderedList>,
]

const goodnotesImport = [
  <Text>Once you have found your downloaded file in the Files app (other other app for non-iPad):</Text>,
  <OrderedList pl={4}>
    <ListItem>Tap the .zip file to uncompress it. Files are compressed to save space and improve your download speed.</ListItem>
    <ListItem>Long press on the file to bring up an options menu. Click Share.</ListItem>
    <ListItem>Scroll down until you see the 'Open in Goodnotes' option. If you'd like to use another app, this is where you'd open in that app instead.</ListItem>
    <ListItem>When the file opens in GoodNotes, select 'Import as New Document'.</ListItem>
  </OrderedList>,
  <Text>Your planner is now available in GoodNotes and ready for use! Happy Planning!</Text>
]

export default function DownloadPlanner() {
  return (
    <ContentPageWithHeader
      baseHeight={'100px'}
      mdHeight={'100px'}
    >
      <Flex
        justify={'center'}>
        <Flex
          color={'#3B3E4B'}
          maxW={'1600'}
          w={'75%'}
          flexWrap={'wrap'}>
          <VStack
            spacing={'6'}
            alignItems={'flex-start'}
            p={20}>
            <Text
              fontSize={'4xl'}
              fontFamily={'ClashDisplay-Medium'}
              color={'3B3E4B'}>
              Download and import your planner into GoodNotes
            </Text>
            <TutorialSection
              title={'1. Find your files on Etsy'}
              paragraphs={findYourFilesParagraphs}
            />
            <TutorialSection
              title={'2. Download the files to your device'}
              paragraphs={downloadFilesParagraphs}
            />
            <TutorialSection
              title={'3. Import the planner into GoodNotes'}
              paragraphs={goodnotesImport}
            />
          </VStack>
        </Flex>
      </Flex>
    </ContentPageWithHeader>
  )
}

import React from 'react';
import {
  ChakraProvider,
  Box,
  Button,
  Flex,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  Wrap,
  WrapItem,
  theme,
  useBreakpointValue
} from '@chakra-ui/react';
import FeatureStack from './FeatureStack';
import HeroImage from './HeroImage';
import ContentPageWithHeader from './ContentPageWithHeader'

export default function Home() {
  return (
    <ContentPageWithHeader title='Contemporary Paperless Planners'>
      <Flex
        justify={'center'}>
        <Flex
          maxW={'1600'}
          flexWrap={'wrap'}>
            <FeatureStack
              title={'Find your perfect planner'}
              body={'Whether it’s color-coding, habit tracking, journaling, or decorating — these planners will help you stay engaged with what keeps you determined and motivated.'}
              buttonText={'Shop Planners'}
              link={'/shop'}
            />
            <FeatureStack
              title={'Personalize & decorate'}
              body={'Check out our extensive sticker collections or import stickers from other sources! Plan every day with a vibe that matches you.'}
              buttonText={'Shop Add-ons'}
              link={'/shop'}
            />
            <FeatureStack
              title={'View planning guides'}
              body={'Whether you’re brand new to planning or just looking for some new ideas, check out these guides to digital planning. Tutorials range from importing your planner into apps to ideas for how to use specific pandonote pages.'}
              buttonText={'Check out Tutorials'}
              link={'/tutorials'}
            />
            <FeatureStack
              title={'Try before you buy'}
              body={'Check out our free preview planner to determine whether the PandoNote planner is for you'}
              buttonText={'View Freebies'}
              link={'/freebies'}
            />
        </Flex>
      </Flex>
    </ContentPageWithHeader>
  )
}

import React from 'react';
import {
  ChakraProvider,
  Box,
  Button,
  Flex,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  Wrap,
  WrapItem,
  OrderedList,
  ListItem,
  theme,
  useBreakpointValue
} from '@chakra-ui/react';
import FeatureStack from '../FeatureStack';
import HeroImage from '../HeroImage';
import ContentPageWithHeader from '../ContentPageWithHeader'
import TutorialSection from './TutorialSection'

const introParagraphs = [
  <Text>When you download a sticker pack from the pandonote shop, it will contain two options:</Text>,
  <OrderedList pl={4}>
    <ListItem>Goodnotes sticker book (.goodnotes file)</ListItem>
    <ListItem>Folders containing raw .png image files</ListItem>
  </OrderedList>,
  <Text>Both of these can be used with goodnotes, and it is up to you how you would like to use stickers in your planning workflow!</Text>
]

const stickerBookParagraphs = [
  <Text>With this option, the sticker book will permanently live inside your planner. Anytime you want to add a sticker, you will navigate to the sticker book pages to copy them.</Text>,
  <OrderedList pl={4}>
    <ListItem>Download the GoodNotes Sticker Book file to your iPad</ListItem>
    <ListItem>Open your planner in GoodNotes</ListItem>
    <ListItem>Navigate to the page where you’d like to add the sticker book</ListItem>
    <ListItem>Click the add page button</ListItem>
    <ListItem>Select where you’d like to insert your stickers (Before, After, or Last Page)</ListItem>
    <ListItem>Click 'Import'</ListItem>
    <ListItem>Select the downloaded sticker book from your files</ListItem>
    <ListItem>Use the lasso tool to select and copy stickers you’d like to use</ListItem>
    <ListItem>To paste a sticker, select the lasso tool and press and hold on the screen</ListItem>
  </OrderedList>,
]

const pngParagraphs = [
  <Text>Using png files will allow you to add stickers without adding additional pages to your planner. Instead, you will be able to add stickers by having the files app open or by adding stickers to your GoodNotes collections for quick access.</Text>,
  <OrderedList pl={4}>
    <ListItem>Download any stickers you’d like to use onto your iPad. If you use dropbox, you can also save all the folders of sticker images to your dropbox account.</ListItem>
    <ListItem>Open your planner in Goodnotes</ListItem>
    <ListItem>Open the Files app on one side of the screen</ListItem>
    <ListItem>Navigate to the downloaded stickers</ListItem>
    <ListItem>Click and drag your stickers into your planner</ListItem>
    <ListItem>Use the lasso tool to select and resize</ListItem>
    <ListItem>To save the stickers to use in the future, select with the lasso tool and click ‘Add Element’</ListItem>
    <ListItem>To access saved stickers, click the element button (sticker with a star in the middle)</ListItem>
  </OrderedList>,
]

export default function DownloadStickers() {
  return (
    <ContentPageWithHeader
      baseHeight={'100px'}
      mdHeight={'100px'}
    >
      <Flex
        justify={'center'}>
        <Flex
          color={'#3B3E4B'}
          maxW={'1600'}
          w={'75%'}
          flexWrap={'wrap'}>
          <VStack
            spacing={'6'}
            alignItems={'flex-start'}
            p={20}>
            <Text
              fontSize={'4xl'}
              fontFamily={'ClashDisplay-Semibold'}
              color={'3B3E4B'}>
              Download and use your planner stickers
            </Text>
            <TutorialSection
              paragraphs={introParagraphs}/>
            <TutorialSection
              title={'Use the sticker book inside an existing planner'}
              paragraphs={stickerBookParagraphs}/>
            <TutorialSection
              title={'Add stickers with png image files'}
              paragraphs={pngParagraphs}/>
          </VStack>
        </Flex>
      </Flex>
    </ContentPageWithHeader>
  )
}

import React from 'react';
import {
  ChakraProvider,
  Box,
  Button,
  Flex,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  Wrap,
  WrapItem,
  OrderedList,
  ListItem,
  theme,
  useBreakpointValue
} from '@chakra-ui/react';
import FeatureStack from '../FeatureStack';
import HeroImage from '../HeroImage';
import ContentPageWithHeader from '../ContentPageWithHeader'


export default function TutorialSection(props) {
  const paragraphs = props.paragraphs.map((p, i) =>
    <Box
      key={i}
      fontSize={'l'}
      fontFamily={'DMSans-Regular'}
      color={'3B3E4B'}>
      {p}
    </Box>
  )
  return (
    <VStack
      pt='8'
      spacing={'6'}
      alignItems={'flex-start'}>
      {props.title &&
        <Text
          fontSize={'2xl'}
          fontFamily={'ClashDisplay-Medium'}
          color={'3B3E4B'}>
          {props.title}
        </Text>
      }
      {paragraphs}
    </VStack>
  )
}
